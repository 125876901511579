class Slider {

    constructor() {
        this.slider = $('[data-slider]');
        this.slider = $('[data-slider-single]');
        this.sliderCarousel = $('[data-slider-carousel]');
        this.sliderCarousel = $('[data-slider-news]');
        this.sliderArrow = $('[data-slider-arrow]');
        this.sliderVideo = $('[data-slider-video]');
        this.sliderLetter = $('[data-slider-letter]');
        this.sliderFootage = $('[data-slider-footage]');
        this.sliderHouse = $('[data-slider-house]');
        this.sliderNewsrow = $('[data-slider-newsrow]');
        this.sliderStage = $('[data-slider-stage]');
        this.initialize();
    }

    initialize() {
        var prev = '<button type="button" class="slick-prev"></button>';
        var next = '<button type="button" class="slick-next"></button>';
        var prev2 = '<button type="button" class="small-prev"></button>';
        var next2 = '<button type="button" class="small-next"></button>';

        this.slider.slick({
            dots: false,
            arrows: true,
            prevArrow: prev2,
            nextArrow: next2,
            infinite: false,
            accessibility: true,
            adaptiveHeight: true,
            mobileFirst: true,
            responsive: [{
                breakpoint: 767,
                settings: {
                    dots: false,
                    arrows: true,
                    fade: true,
                    cssEase: 'ease-out'
                }
            }]
        });
        this.sliderStage.slick({
            dots: true,
            arrows: false,
            infinite: false,
            accessibility: true,
            adaptiveHeight: true,
            unslick: true,
            responsive: [{
                    breakpoint: 2048,
                    settings: "unslick"
                },
                {
                    breakpoint: 767,
                    settings: {
                        dots: true,
                        arrows: false,
                        fade: true,
                        infinite: false,
                        accessibility: true,
                        adaptiveHeight: false,
                        cssEase: 'ease-out'
                    }
                }
            ]
        });

        this.sliderCarousel.slick({
            dots: false,
            arrows: true,
            prevArrow: prev,
            nextArrow: next,
            infinite: false,
            speed: 300,
            slidesToShow: 4,
            slidesToScroll: 4,
            responsive: [{
                    breakpoint: 1280,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                        infinite: true
                    }
                },
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                        infinite: true

                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        dots: true,
                        arrows: false,
                        slidesToShow: 2,
                        slidesToScroll: 2
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        dots: true,
                        arrows: false,
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        dots: true,
                        arrows: false,
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
                // You can unslick at a given breakpoint now by adding:
                // settings: "unslick"
                // instead of a settings object
            ]
        });
        this.sliderNewsrow.slick({
            unslick: true,
            dots: false,
            arrows: false,
            slidesToShow: 2,
            responsive: [{
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        infinite: true

                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        dots: true,
                        arrows: false,
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        dots: true,
                        arrows: false,
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
                // You can unslick at a given breakpoint now by adding:
                // settings: "unslick"
                // instead of a settings object
            ]
        });
        this.sliderArrow.slick({
            dots: false,
            arrows: true,
            prevArrow: prev,
            nextArrow: next,
            infinite: false,
            speed: 300,
            slidesToShow: 3,
            slidesToScroll: 3,
            responsive: [{
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        infinite: true

                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        dots: false,
                        arrows: true,
                        slidesToShow: 2,
                        slidesToScroll: 2
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        dots: true,
                        arrows: false,
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
                // You can unslick at a given breakpoint now by adding:
                // settings: "unslick"
                // instead of a settings object
            ]
        });
        this.sliderVideo.slick({
            dots: false,
            arrows: true,
            prevArrow: prev2,
            nextArrow: next2,
            infinite: false,
            speed: 300,
            slidesToShow: 3,
            slidesToScroll: 1,
            responsive: [{
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        infinite: true

                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        dots: false,
                        arrows: true,
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        dots: false,
                        arrows: true,
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        dots: false,
                        arrows: true,
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
                // You can unslick at a given breakpoint now by adding:
                // settings: "unslick"
                // instead of a settings object
            ]
        });
        this.sliderFootage.slick({
            dots: false,
            arrows: true,
            prevArrow: prev2,
            nextArrow: next2,
            infinite: false,
            speed: 300,
            slidesToShow: 1,
            slidesToScroll: 1,
            responsive: [{
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        infinite: true

                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        dots: false,
                        arrows: true,
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        dots: false,
                        arrows: true,
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
                // You can unslick at a given breakpoint now by adding:
                // settings: "unslick"
                // instead of a settings object
            ]
        });
        this.sliderHouse.slick({
            dots: false,
            arrows: true,
            prevArrow: prev2,
            nextArrow: next2,
            infinite: false,
            speed: 300,
            slidesToShow: 2,
            slidesToScroll: 1,
            responsive: [{
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        infinite: true

                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        dots: false,
                        arrows: true,
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        dots: false,
                        arrows: true,
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
                // You can unslick at a given breakpoint now by adding:
                // settings: "unslick"
                // instead of a settings object
            ]
        });
        this.sliderLetter.slick({
            dots: true,
            arrows: true,
            prevArrow: prev,
            nextArrow: next,
            infinite: false,
            speed: 300,
            slidesToShow: 1,
            slidesToScroll: 1,
            responsive: [{
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        infinite: true

                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        dots: false,
                        arrows: true,
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        dots: false,
                        arrows: true,
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
                // You can unslick at a given breakpoint now by adding:
                // settings: "unslick"
                // instead of a settings object
            ]
        });
    }
}

new Slider();