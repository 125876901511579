class Hamburger {

    constructor() {
        this.hamburger = $('[data-hamburger]');
        this.initialize();
    }

    initialize() {
        this.hamburger.click(function(){
            $(this).toggleClass('open');
        });
    }
  }

  new Hamburger();
