'use strict';

import './config/base.js';

import './accordion/accordion';
import './autocomplete/autocomplete';
import './hamburger/hamburger';
import './header/header';
import './map/map';
import './menu/menu';
import './pagination/pagination';
import './slider/slider';
import './tabs/tabs';
import './counter/counter';
import './popup/popup';
