class Pagination {

  constructor() {
      this.initialize();
  }

  initialize() {

  }
}

new Pagination();
