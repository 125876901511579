class Accordion {

    constructor() {
        this.accordion = $('[data-accordion]');
        this.accordionItem = this.accordion.find('[data-accordion-item]');
        this.initialize();
    }

    initialize() {
        this.accordionItem.each(function() {
            var el = $(this);
            var header = el.find('[data-accordion-header]');
            var body = el.find('[data-accordion-body]');

            header.click(function() {
                body.slideToggle(300);
                body.toggleClass('accordion__body_open');
                header.toggleClass('open');
            });
        });

        $('[data-accordion-head]').click(function() {
            var el = $(this);
            var parent = el.closest('[data-accordion]');
            var body = el.next();

            if (parent.attr('data-accordion') == 'mobile') {
                if ($(window).width() < 992) {

                    if (el.attr('data-accordion-head') == 'inner') {
                        var elInner = el.find('[data-accordion-inner]');

                        elInner.click(function() {
                            parent.find('[data-accordion-head]').removeClass('active');
                            parent.find('[data-accordion-body]').removeClass('active');

                            elInner.parent().addClass('active');
                            body.addClass('active');
                        });
                    } else {
                        parent.find('[data-accordion-head]').removeClass('active');
                        parent.find('[data-accordion-body]').removeClass('active');

                        el.addClass('active');
                        body.addClass('active');
                    }
                }
            }
        });
    }
}

new Accordion();