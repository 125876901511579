'use strict';

// стилизация элементов форм
$('select').styler();

// маска в инпутах
$('[data-date]').mask("99/99/9999");
$('[data-phone]').mask("(999) 999-9999");
$('[data-tin]').mask("99-9999999");
$('[data-ssn]').mask("999-99-9999");

$('[data-date-completed]').mask("99/99/9999", {
    placeholder: " ",
    completed: function() {
        alert("You typed the following: " + $(this).val());
    },
    autoclear: false
});

$.mask.definitions['~'] = '[+-]';
$('[data-phone-definitions]').mask("~9.99 ~9.99 999");

$('[data-search]').click(function() {
    $('[data-search-top]').toggleClass('open');
    $(this).toggleClass('open');
});

$('[data-sum]').click(function() {
    $('.elem-size').toggleClass('open');
    $('.elem-size__table').toggleClass('open');
});

$('[data-hamburger]').click(function() {
    $('.menu-mobile').toggleClass('open');
});
$('[data-close]').click(function() {
    $(this).parent().toggleClass('close');
});
$('.menu-right__item').click(function() {
    $('.menu-right__item').removeClass('active');
    $(this).addClass('active');
});


var num = 487; //number of pixels before modifying styles
$(window).bind('scroll', function() {
    if ($(window).scrollTop() > num) {
        $('[menu-sticky]').addClass('fixed');
    } else {
        $('[menu-sticky]').removeClass('fixed');
    }
});

$('[data-home-icon]').mouseover(function() {
    var el = $(this);
    var index = el.attr('data-home-icon') - 1;
    var parent = el.closest('[data-home]');
    var info = parent.find('[data-home-info]');

    $('[data-home-icon]').removeClass('active');
    el.addClass('active');

    info.removeClass('active');
    info.eq(index).addClass('active');
});

$('[data-home-info]').mouseover(function() {
    var el = $(this);
    var index = el.attr('data-home-info') - 1;
    var parent = el.closest('[data-home]');
    var icon = parent.find('[data-home-icon]');

    $('[data-home-info]').removeClass('active');
    el.addClass('active');

    icon.removeClass('active');
    icon.eq(index).addClass('active');
});

// Валидация форм
$('[data-validate]').validate({
    errorClass: "invalid",
    validClass: "success",
    rules: {
        name: "required",
        email: {
            required: true,
            email: true
        },
        confirm: {
            equalTo: "#password"
        }
    },
    messages: {
        name: "Please specify your name",
        email: {
            required: "We need your email address to contact you",
            email: "Your email address must be in the format of name@domain.com"
        }
    }
});

// Cache selectors
var lastId,
    topMenu = $(".menu-right"),
    topMenuHeight = topMenu.outerHeight() + 15,
    // All list items
    menuItems = topMenu.find("a"),
    // Anchors corresponding to menu items
    scrollItems = menuItems.map(function() {
        var item = $($(this).attr("href"));
        if (item.length) {
            return item;
        }
    });
console.log(scrollItems);

// Bind click handler to menu items
// so we can get a fancy scroll animation
menuItems.click(function(e) {
    var href = $(this).attr("href"),
        offsetTop = href === "#" ? 0 : $(href).offset().top - topMenuHeight + 1;
    $('html, body').stop().animate({
        scrollTop: offsetTop
    }, 300);
    e.preventDefault();
});

// Bind to scroll
$(window).scroll(function() {
    // Get container scroll position
    var fromTop = $(this).scrollTop() + topMenuHeight;

    // Get id of current scroll item
    var cur = scrollItems.map(function() {
        if ($(this).offset().top < fromTop)
            return this;
    });
    // Get the id of the current element
    cur = cur[cur.length - 1];
    var id = cur && cur.length ? cur[0].id : "";

    if (lastId !== id) {
        lastId = id;
        // Set/remove active class
        menuItems
            .parent().removeClass("active")
            .end().filter("[href='#" + id + "']").parent().addClass("active");
    }
});