class Tabs {

    constructor() {
        this.tabs = $('[data-tabs]');
        this.tabsMenu = $('[data-tabs-menu]');
        this.tabsSlider = $('[data-tabs-slider]');
        this.initialize();
    }

    initialize() {
        if (this.tabs.length) {
            var tabs = this.tabs;
            var tabsLink = tabs.find('[data-tabs-head]').children();
            var tabsBody = tabs.find('[data-tabs-body]');

            tabsLink.click(function() {
                var el = $(this);
                var index = el.index();
                var item = tabsBody.children().eq(index);

                tabsLink.removeClass('active');
                tabsBody.children().removeClass('active');

                el.addClass('active');
                item.addClass('active');
            });

        }

        if (this.tabsMenu.length) {
            var tabs = this.tabsMenu.parent().parent();
            var sub = $('tabs-menu__link');
            var subLink = $('tabs-menu__wrapper').children();;
            var tabsLink = tabs.find('[data-tabs-head]').children();
            var tabsBody = tabs.find('[data-tabs-body]');

            tabsLink.click(function(e) {
                var el = $(this);
                var index = el.index();

                if (tabsBody.attr('data-accordion')) {
                    index = el.index() * 2 + 1;

                    tabsLink = tabs.find('[data-tabs-body]').children('[data-accordion-head]');
                }

                var item = tabsBody.children().eq(index);


                el.parent().children().removeClass('active');
                tabsBody.children().removeClass('active');

                el.addClass('active');
                item.addClass('active');

                e.preventDefault();
            });

            subLink.click(function(e) {
                var el = $(this);
                var index = el.index();

                if (tabsBody.attr('data-accordion')) {
                    index = el.index() * 2 + 1;
                }

                var item = tabsBody.children().eq(index);

                tabsLink.removeClass('active');
                tabsBody.children().removeClass('active');

                el.addClass('active');
                item.addClass('active');

                e.preventDefault();
            });

            $('[data-tabs-inner]').click(function(e) {
                var el = $(this);
                var parentIndex = el.closest('.active').index();

                if (tabsBody.attr('data-accordion')) {
                    parentIndex = el.closest('.active').index() * 2 + 1;
                }

                var index = el.index();

                el.parent().children().removeClass('active');
                el.addClass('active');

                $('body').find('[data-tabs-body]').children().eq(parentIndex).children().removeClass('active');
                $('body').find('[data-tabs-body]').children().eq(parentIndex).children().eq(index).addClass('active');

                e.preventDefault();
            });

        }

        if (this.tabsSlider.length) {
            $('[data-tabs-slider-head]').each(function() {
                var head = $(this);
                var link = head.children();

                link.click(function(e) {
                    var el = $(this);
                    var index = el.index();

                    var tabs = el.closest('[data-tabs-slider]');

                    var body = tabs.children('[data-tabs-slider-body]');
                    var item = body.children();

                    link.removeClass('active');
                    el.addClass('active');

                    item.removeClass('active');
                    item.eq(index).addClass('active');

                    $('[data-slider-footage]').slick('refresh');
                    $('[data-slider-house]').slick('refresh');

                    e.preventDefault();
                });
            });
        }
    }
}

new Tabs();
