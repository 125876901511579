class Header {

    constructor() {
        this.header = $('[data-header-scroll]');
        this.headerDown = $('[data-header-scroll-down]');
        this.initialize();
    }

    initialize() {

        var header = this.header;
        var headerDown = this.headerDown;
        var tempScrollTop, currentScrollTop, tempScrollTopDown = 0;

        // Обычный скролл, шапка фиксируется ниже высоты vh
        function scroll() {
            var vh = $(window).height();
            currentScrollTop = $(window).scrollTop();

            var vh = 0;

            if (currentScrollTop > vh) {
                header.addClass('fixed');
            } else {
                header.removeClass('fixed');
            }
        }

        // Шапка фиксорованна только при скролле вниз
        function scrollDown() {
            var vh = $(window).height();
            currentScrollTop = $(window).scrollTop();

            if (tempScrollTop < currentScrollTop ) {
                //scrolling down
                if (currentScrollTop > vh) {
                    headerDown.addClass('fixed');
                }
            } else {
                //scrolling up
                headerDown.removeClass('fixed');
                tempScrollTopDown = tempScrollTop;
            }

            tempScrollTop = currentScrollTop;
        }

        $(window).scroll(function () {

            if (header.length) {
                scroll();
            }

            if (headerDown.length) {
                scrollDown();
            }
        });
    }
  }

  new Header();
