class Autocomplete {

    constructor() {
        this.autocomplete = $('[data-autocomplete]');
        this.autocompleteCity = $('[data-autocomplete-city]');
        this.initialize();
    }

    initialize() {
        if (this.autocomplete.length) {

        }

        if (this.autocompleteCity.length) {
            let searchResult = [];

            this.autocompleteCity.keyup(function () {
                //по мере ввода фразы, событие будет срабатывать всякий раз
                let searchQuery = $(this).val();
                //массив, в который будем записывать результаты поиска
                searchResult = [];
                //делаем запрос к геокодеру
                $.getJSON('http://geocode-maps.yandex.ru/1.x/?format=json&geocode=' + searchQuery, function (data) {
                  //геокодер возвращает объект, который содержит в себе результаты поиска
                  //для каждого результата возвращаются географические координаты и некоторая дополнительная информация
                  //ответ геокодера легко посмотреть с помощью console.log();
                  for (let i = 0; i < data.response.GeoObjectCollection.featureMember.length; i++) {
                    //записываем в массив результаты, которые возвращает нам геокодер
                    searchResult.push({
                      label: data.response.GeoObjectCollection.featureMember[i].GeoObject.description + ' - ' + data.response.GeoObjectCollection.featureMember[i].GeoObject.name,
                      value: data.response.GeoObjectCollection.featureMember[i].GeoObject.name,
                      longlat: data.response.GeoObjectCollection.featureMember[i].GeoObject.Point.pos });
                  }

                  console.log(searchResult);
                  //подключаем к текстовому полю виджет autocomplete
                  this.autocompleteCity.autocomplete({
                    //в качестве источника результатов указываем массив searchResult
                    source: searchResult,
                    select: function select(event, ui) {
                      //это событие срабатывает при выборе нужного результата
                      console.log(ui.item);
                      let longlat = ui.item.longlat.split(" ");

                      let myPlacemark = new ymaps.Placemark([longlat[1], longlat[0]], {
                        balloonContentBody: ui.item.label,
                        hintContent: ui.item.label
                      });
                      map.geoObjects.add(myPlacemark);
                      map.setCenter([longlat[1], longlat[0]], 13);

                      locationChooser($('#locationChooser'));
                    }
                  });

                  $('.ui-autocomplete').width(this.autocompleteCity.width());
                });
            });

            $.extend($.ui.autocomplete, {
                escapeRegex: function escapeRegex(value) {
                  return value.replace(/[\-\[\]{}()*+?.,\\\^$|#\s]/g, "\\$&");
                },
                filter: function filter(array, term) {
                  let matcher = new RegExp($.ui.autocomplete.escapeRegex(term), "i");
                  return $.grep(array, function (value) {
                    return matcher.test(value.label || value.value || value);
                  });
                }
            });
        }
    }
}

new Autocomplete();
